import React, { useEffect, useState } from 'react';
import { FaPhoneAlt, FaYelp } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import styles from './Footer.styles';
import Text from '../Text/Text';
import strings from '../../Utils/Localization/Localization';

const Footer = () => {
    const footerData = [
      {
        icon: null,
        href: null,
        text: 'Construction LLC',
        title: 'Rivera & Sons',
      },
      {
        icon: <MdEmail className='component_footer_icon' style={ styles.icon } />,
        href: 'mailto: reynaldorivera5515@gmail.com',
        text: 'reynaldorivera5515@gmail.com',
        title: strings.common.email,
      },
      {
        icon: <FaPhoneAlt className='component_footer_icon' style={ styles.icon } />,
        href: 'tel: (404) 388-5515',
        text: '+1 (404) 388-5515',
        title: strings.common.callUs,
      },
      {
        icon: <FaYelp className='component_footer_icon' style={ styles.icon } />,
        href: 'https://www.yelp.com/biz/rivera-and-sons-gonzales',
        text: strings.common.clickHereToSeeOurPage,
        title: 'Yelp',
      },
    ];
    const [showFooterIcons, setShowFooterIcons] = useState(false);

    useEffect(/* istanbul ignor next */ () => {
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return /* istanbul ignore next */ () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const handleResize = /* istanbul ignore next */ () => {
      if (window.innerWidth > 900) {
        setShowFooterIcons(true);
      } else {
        setShowFooterIcons(false);
      };
    };

    return (
        <footer className='component_footer_container' style={ styles.container }>
            {
                footerData?.map((data, index) => (
                    showFooterIcons
                    ? (
                        <a className='component_footer_section_container' href={ data?.href } key={ `${ index }_${ data?.title }` }>
                            <div>
                                <Text style={ styles.title } text={ data?.title } />
                                <Text style={ styles.description } text={ data?.text } />
                            </div>
                        </a>
                    ) : !!data?.icon &&<a className='component_footer_section_container' href={ data?.href } key={ `${ index }_${ data?.title }` }>{ data?.icon }</a>
                ))
            }
        </footer>
    );
};

export default Footer;
