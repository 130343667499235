import { doc, increment, setDoc } from 'firebase/firestore';
import moment from 'moment';
import firebase from '../firebase';

export const addMonthlyVisit = async () => {
    const collection = 'visits'
    const document = moment().format('YYYY')
    const month = moment().format('MMMM')
    const docRef = doc(firebase.db, collection, document)
    await setDoc(docRef, { [month]: increment(1) }, { merge: true })
};