import { FaCalculator } from 'react-icons/fa';
import { GiStarsStack } from 'react-icons/gi'
import { IoPeople, IoShieldCheckmark } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import './Cover.css';
import { styles } from './Cover.styles';
import COMPANY_LOGO from '../../Assets/Company/logo.png';
import IMAGE from '../../Assets/Images/image.jpg';
import Footer from '../../Components/Footer/Footer';
import ImageSlider from '../../Components/ImageSlider/ImageSlider';
import { addMonthlyVisit } from '../../Services/Firebase/Database/Database';
import { getPictures } from '../../Services/Firebase/Storage/Storage';
import strings from '../../Utils/Localization/Localization';

const Cover = () => {
  const [pictures, setPictures] = useState([]);
  const [showHeaderImage, setShowHeaderImage] = useState(true);
  const guarantees = [
    {
      icon: <GiStarsStack className='why_us_icon' />,
      text: strings.whyUs.experienced,
    },
    {
      icon: <FaCalculator className='why_us_icon' />,
      text: strings.whyUs.freeQuotes,
    },
    {
      icon: <IoShieldCheckmark className='why_us_icon' />,
      text: strings.whyUs.insured,
    },
    {
      icon: <IoPeople className='why_us_icon' />,
      text: strings.whyUs.seHablaEspanol,
    }
  ];
  const services = [
    strings.ourServices.houseFraming,
    strings.ourServices.homeFraming,
    strings.ourServices.residentailFraming,
    strings.ourServices.wallFraming,
    strings.ourServices.windowFraming,
    strings.ourServices.roofFraming,
    strings.ourServices.framingProjects,
    strings.ourServices.decksAndPations,
  ];

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      addMonthlyVisit();
    };
    getPictures(setPictures);
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth > 820) {
      setShowHeaderImage(true);
    } else {
      if (window.innerWidth < 660) {
        setShowHeaderImage(false);
      }
    };
  };

  return (
    <main className='container'>
      <header className='header slanted-div'>
        <img alt='Rivera and Sons Company Logo' className='company_logo' src={ COMPANY_LOGO } />
        <div style={ styles.textContainer }>
          <h1 className='header_title'>{ strings.intro.title }</h1>
          <p className='header_sub_title' style={ styles.subtitle }>{ strings.intro.body }</p>
        </div>
        { showHeaderImage && <img alt='Rivera and Sons Header' className='header_image' src={ IMAGE } />}
      </header>
      <section className='body'>
        <div className='why_us'>
          <h1 className='body_title'>{ strings.whyUs.title }</h1>
          <div className='why_us_container'>
            {
              guarantees?.map((guarantee, index) => (
                <div className='why_us_section' key={ `${ index }_${ guarantee?.text }` }>
                  { guarantee?.icon }
                  <p className='why_us_section_text' >{ guarantee?.text }</p>
                </div>
              ))
            }
          </div>
        </div>
        <div className='services'>
          <h1 className='body_title'>{ strings.ourServices.title }</h1>
          {
            services?.map((service, index) => (
              <p className='service' key={ `${ index }_${ services }` }>{ service }</p>
            ))
          }
        </div>
        <div className='our_work'>
          <h1 className='body_title'>{ strings.ourWork.title }</h1>
          <ImageSlider pictures={ pictures } />
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Cover;
