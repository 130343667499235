import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBxq8GVM8NYaiTSZWxICWlZlPYuT_A8QOs",
    authDomain: "rivera-and-sons.firebaseapp.com",
    projectId: "rivera-and-sons",
    storageBucket: "rivera-and-sons.appspot.com",
    messagingSenderId: "506428834773",
    appId: "1:506428834773:web:6410195cc4b08b4639f35c",
    measurementId: "G-4SNJLG8SJ3"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const firebase = { app, db };

export default firebase;
