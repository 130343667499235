import Colors from '../../Platform/Colors';

const styles = {
    container: {
        alignItems: 'flex-start',
        backgroundColor: Colors.orange,
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        paddingBottom: 25,
        paddingTop: 25,
        position: 'fixed',
        width: '100%',
        zIndex: 5,
    },
    description: {
          color: Colors.white,
          letterSpacing: 2,
          fontSize: 14,
          fontWeight: '700',
          marginTop: 0,
          whiteSpace: 'pre',
    },
    icon: {
        color: Colors.white,
        height: 36,
        width: 36,
    },
    title: {
          color: Colors.white,
          letterSpacing: 2,
          fontSize: 20,
          fontWeight: '700',
          marginBottom: 0,
    },
};

export default styles;
