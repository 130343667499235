import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaCircle } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './ImageSlider.css';
import styles from './ImageSlider.styles';
import { getIsSmallScreen } from './helper';

const ImageSlider = ({ pictures }) => {
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isScreenSmall, setIsScreenSmall] = useState(false)
  const height = isScreenSmall ? 250 : 500

  useEffect(/* istanbul ignore next */ () => {
    getIsSmallScreen(setIsScreenSmall)
    window.addEventListener('resize', () => getIsSmallScreen(setIsScreenSmall))

    return () => window.removeEventListener('resize', () => getIsSmallScreen(setIsScreenSmall))
  }, [])

  useEffect(/* istanbul ignore next */ () => {
    const slideInterval = setInterval(nextSlide, 3000);

    return () => clearInterval(slideInterval);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [index]);

  const nextSlide = () => {
    const nextIndex = index === (pictures?.length - 1) ? 0 : index + 1;
    setIndex(nextIndex);
  };

  return (
    <div className='container' style={ styles.container }>
      <div className='image_slider_frame' style={ { height, width: '60%' } } />
      <div className='image_slider_container' style={ { ...styles.image_slider_container, height} }>
        { isLoading && <Skeleton baseColor='#DDDDDD' height={ height } width={ '100%' } /> }
        <img
          alt={ `pic_${ index }` }
          className={ `image_#${index}` }
          onLoad={ /* istanbul ignore next */ () => setIsLoading(false) }
          src={ pictures[index] }
          style={ { ...styles.img, ...{ display: !!isLoading ? 'none' : 'initial' } } }
        />
        <div style={ styles.buttons }>
          {
            pictures?.map((img, imgIndex) => (
              <button key={ `${ index }_${ img }` } style={ styles.button } onClick={ /* istanbul ignore next */ () => setIndex(imgIndex) }>
                <FaCircle
                  style={ {
                    ...styles.buttonIcon,
                    ...{ color: index === imgIndex ? '#EE6C4D' : '#ffffff' }
                  } }
                />
              </button>
            ))
          }
        </div>
        <button
          disabled={ index === 0 }
          onClick={ /* istanbul ignore next */ () => setIndex(index - 1) }
          style={ { ...styles.arrowContainer, ...{ left: '2%' } } }
        >
          <FaChevronLeft
            style={ {
              ...styles.arrow,
              ...{
                color: index !== 0 ? '#ffffff' : 'transparent',
                cursor: index !== 0 ? 'pointer' : null,
              }
            } }
          />
        </button>
        <button
          disabled={ index === (pictures?.length - 1) }
          onClick={ /* istanbul ignore next */ () => setIndex(index + 1) }
          style={ { ...styles.arrowContainer, ...{ right: '2%' } } }
        >
          <FaChevronRight
            style={ {
              ...styles.arrow,
              ...{
                color: index !== (pictures?.length - 1) ? '#ffffff' : 'transparent',
                cursor: index !== (pictures?.length - 1) ? 'pointer' : null,
              },
            } }
          />
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;

ImageSlider.defaultProps = {
  pictures: [],
}
