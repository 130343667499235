import LocalizedStrings from 'react-localization';

const localization =  new LocalizedStrings({
    en: {
        common: {
            callUs: 'Call Us',
            clickHereToSeeOurPage: 'Click here to see our page',
            email: 'email',
        },
        intro: {
            body: 'Deticated to high quality construction, we strive to build fantastic homes for families.  We are the best in our region at construction.',
            title: 'We Build\nYour Dream',
        },
        ourServices: {
            decksAndPations: 'Decks & Patios',
            framingProjects: 'Large & Small Framing Projects',
            homeFraming: 'Home Framing',
            houseFraming: 'House Framing',
            residentailFraming: 'Residential Framing',
            roofFraming: 'Roof Framing',
            title: 'Our Services',
            wallFraming: 'Wall Framing',
            windowFraming: 'Window Framing',
        },
        ourWork: {
            title: 'Our Work',
        },
        whyUs: {
            experienced: 'Experienced in\nthe Industry',
            freeQuotes: 'Free Quotes',
            insured: 'Insured',
            seHablaEspanol: 'Se Habla Español',
            title: 'Why Rivera & Sons?'
        },
    },
    es: {
        common: {
            callUs: 'LLámanos',
            clickHereToSeeOurPage: 'Haga clic aquí para ver nuestra página',
            email: 'Correo Electrónico',
        },
        intro: {
            body: 'Dedicados a la construcción de alta calidad, nos esforzamos por construir fantásticas viviendas para familias. Somos los mejores en nuestra región en construcción.',
            title: 'Construimos tu sueño',
        },
        ourServices: {
            decksAndPations: 'Terrazas y Patios',
            framingProjects: 'Proyectos de marco grandes y pequeños',
            homeFraming: 'Enmarcado del Hogar',
            houseFraming: 'Enmarcado de la Casa',
            residentailFraming: 'Enmarcado Residencial',
            roofFraming: 'Estructura del techo',
            title: 'Nuestros Servicios',
            wallFraming: 'Encuadre de pared',
            windowFraming: 'Marco de ventana',
        },
        ourWork: {
            title: 'Nuestro Trabajo',
        },
        whyUs: {
            experienced: 'Experiencia en\nla Industria',
            freeQuotes: 'Cotizaciones gratis',
            insured: 'Asegurado',
            seHablaEspanol: 'Se Habla Español',
            title: 'Por que Rivera & Sons?'
        },
    },
});

export default localization;
