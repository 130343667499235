export const styles = {
  subtitle: {
    fontSize: 26,
    fontWeight: '300',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    maxWidth: 700,
  },
  textContainer: {
    maxWidth: '50vw',
  },
};