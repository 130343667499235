import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import firebase from '../firebase';

const storage = getStorage(firebase.app);
const picturesRef = ref(storage, 'pictures/');

export const getPictures = async setPictures => {
    try {
        const res = await listAll(picturesRef);
        const items = [];
    
        for (let index = 0; index < res?.items?.length; index++) {
            const url = await getDownloadURL(ref(storage, res?.items[index]));
            items.push(url);
        };

        setPictures(items);
    } catch (error) {
        console.log('ERROR: ', error);
    };
};
